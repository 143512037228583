import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { TotalCost } from './TotalCost';
import { TotalQuantity } from './TotalQuantity';
import { CostPerItem } from './CostPerItem';
import { AllocationsList } from './allocations-list/AllocationsList';
import { Store } from '../../../../../domain/User/Store';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

export const allocationsTestId = 'allocationsComponent';

export interface AllocationsProps {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly stores: Store[];
  readonly disabled: boolean;
}

export const Allocations = (props: AllocationsProps) => {
  const { purchaseOrder, stores, disabled } = props;

  return useMemo(
    () => (
      <div data-testid={allocationsTestId}>
        <AllocationsList stores={stores} disabled={disabled} />
        <Box mb="0.625rem">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TotalQuantity />
            </Grid>
            <Grid item xs={6}>
              <CostPerItem purchaseOrder={purchaseOrder} disabled={disabled} />
            </Grid>
          </Grid>
        </Box>
        <TotalCost />
      </div>
    ),
    [purchaseOrder, disabled, stores]
  );
};
