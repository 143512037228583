import React, { useEffect, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import clsx from 'clsx';
import { useAsync } from 'react-async';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { get, isArray, first } from 'lodash';

import { useGlobalContext } from '../../../../context/global/GlobalContext';
import { openSnackbarAction } from '../../../../context/global/snackbar/snackbar-actions';
import {
  createSubmitPurchaseOrder,
  createDraftPurchaseOrder,
  checkLegalLimits,
  handledCreateDraftPurchaseOrder,
  SUBMIT_ERROR_MESSAGE
} from '../../../../context/purchase-orders/single/purchase-order-thunks';
import { APIErr, APIError } from '../../../../domain/APIError';
import {
  LegalEngineResponseItem,
  toIllegalResponseList
} from '../../../../domain/Legal/LegalEngineResponseItem';
import { LegalEngineErrorCode } from '../../../../domain/Legal/LegalEngineError';
import { PoStatus } from '../../../../domain/PurchaseOrder/PoStatus';
import { Condition } from './Condition';
import { CancelPurchaseOrderDialog } from './CancelPurchaseOrderDialog';
import { PoCanceller, CancelButtonProps, buttonTestId } from './PoCanceller';
import { DeleteDraftPO } from './delete-draft-po/DeleteDraftPo';
import { Pricing } from './pricing/Pricing';
import { PleaseWaitBox } from '../../../common/please-wait-box/PleaseWaitBox';
import { Modal } from '../../../common/modal/Modal';
import { createPoSubmitSuccessMessage } from '../../../layout/snackbar/messsages/po-submit-success/PoSubmitSuccessMessage';
import { Allocations } from './allocations/Allocations';
import { Classifications } from './classifications/Classifications';
import { Props } from '../PurchaseOrder';
import { toLegalEngineRequest } from './purchase-order-form-mappers';
import { HelpText } from '../../../layout/help-text/HelpText';
import { FieldWithTooltip } from '../../../common/field-with-tooltip/FieldWithTooltip';
import { LooseWorkPrompt } from '../../../common/loose-wrok-prompt/LooseWorkPrompt';
import { PurchaseOrdersRoute } from '../../../app/routes/PurchaseOrdersRoute';
import { ReactComponent as BackIcon } from '../../../../assets/images/back-icon.svg';
import { Button } from '../../../common/Button';
import { PanelTitle, PoFormLabel, PoFormField } from './po-form-config';
import { Store } from '../../../../domain/User/Store';
import { createGenericMessage } from '../../../layout/snackbar/messsages/generic-message/CreateGenericMessage';
import { isNonEmptyValue } from '../../../../utils/isNonEmptyValue';
import { PurchaseOrder } from '../../../../domain/PurchaseOrder/PurchaseOrder';
import { Description } from './description/Description';
import { hasMaxQuantityError } from './allocations/TotalQuantity';
import { generateFormDefaults } from './generateFormDefaults';
import { PurchaseOrderFormValues } from './PurchaseOrderFormValues';
import { LegalEngineRequest } from '../../../../domain/Legal/LegalEngineRequest';
import { Either, left } from '../../../../utils/Either';
import { General } from './general/General';
import { FormCard } from './form-card/FormCard';
import { FormCardTitle } from './form-card/FormCardTitle';
import { FormCardContent } from './form-card/FormCardContent';
import { useDuplicatePo } from './useDuplicatePo';

import styles from './PurchaseOrderForm.module.scss';
import {
  IllegalStateInfo,
  IllegalValueBox
} from './illegal-values-box/IllegalValueBox';
import { calculateValue } from '../../../../utils/helper-functions';
import { PricingCalculator } from '../../../../utils/PricingCalculator';

const getDefinedValues = (formValues: PurchaseOrderFormValues): string[] =>
  Object.values(formValues).filter((value: string) => value && value !== '');

export interface PurchaseOrderProps extends Props {
  readonly purchaseOrderId: string | undefined;
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly stores: Store[];
  readonly isPurchaseOrderPending?: boolean;
  readonly isSaveDraftDisabled: boolean;
  readonly setIsSaveDraftDisabled?: any;
  readonly isSubmitDisabled?: boolean;
  readonly setIsSubmitDisabled?: any;
}

const buttonsTexts = {
  back: 'Back',
  cancel: 'Cancel',
  duplicatePO: 'Duplicate PO',
  cancelPO: 'Cancel PO',
  cancelledPO: 'Cancelled PO',
  saveDraft: 'Save as Draft',
  submitOrder: 'Submit Order'
};

// eslint-disable-next-line
export const PurchaseOrderForm = (props: PurchaseOrderProps) => {
  const {
    purchaseOrder,
    stores,
    isSaveDraftDisabled,
    setIsSaveDraftDisabled,
    isSubmitDisabled,
    setIsSubmitDisabled,
    match: {
      params: { purchaseOrderId }
    },
    history
  } = props;

  const methods = useForm<PurchaseOrderFormValues>({
    mode: 'onChange',
    defaultValues: generateFormDefaults(stores, purchaseOrder || undefined)
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors,
    formState: { isDirty, isValid },
    reset,
    trigger
  } = methods;

  const globalContext = useGlobalContext();
  const { handleDuplicate } = useDuplicatePo(purchaseOrderId, {
    getValues,
    setValue,
    reset,
    trigger
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);
  const [illegalResponse, setIllegalResponse] = useState<
    LegalEngineResponseItem[]
  >([]);
  const [cancelText, setCancelText] = useState(buttonsTexts.back);
  const [apiError, setApiError] = useState<APIErr | Error | null>(null);
  const [
    isDeleteConfirmationPopupOpen,
    setDeleteConfirmationPopupStatus
  ] = useState(false);
  const [isDeleteFinished, setDeleteFinished] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [helpTitle, setHelpTitle] = useState('');

  const definedValues = getDefinedValues(getValues() as any);

  const isViewMode = !!(
    purchaseOrder && purchaseOrder.poStatus === PoStatus.SUBMITTED
  );
  const isEditMode = !!(
    purchaseOrder && purchaseOrder.poStatus === PoStatus.DRAFT
  );
  const isCreateMode = purchaseOrderId === undefined;
  const isCanceled: boolean =
    (purchaseOrder && purchaseOrder.isCanceled) || false;
  const purchaseOrderHeader = isCreateMode
    ? 'CREATE A PO'
    : isEditMode
    ? `Edit PO #${purchaseOrderId}`
    : `PO #${purchaseOrderId}`;
  const showLooseWorkPrompt =
    isDirty &&
    !openAlert &&
    !isDeleteConfirmationPopupOpen &&
    !isSaveDraftDisabled &&
    !isSubmitDisabled &&
    !isDeleteFinished;

  let skipLegal = false;

  const redirectToDraftPurchaseOrders = (
    oneOrManyPurchaseOrders: PurchaseOrder | PurchaseOrder[]
  ) => {
    setIsSaveDraftDisabled(true);

    toggleModal(false);

    if (isArray(oneOrManyPurchaseOrders)) {
      history.push(PurchaseOrdersRoute.Submitted);

      const submittedIds: string = oneOrManyPurchaseOrders.reduce(
        (ids: string, po: PurchaseOrder) => {
          if (po.id) {
            return ids ? `${ids}, #${po.id}` : `${po.id}`;
          }
          return ids;
        },
        ''
      );

      globalContext.dispatch(
        openSnackbarAction(createPoSubmitSuccessMessage(submittedIds))
      );
    } else {
      history.push(PurchaseOrdersRoute.Draft);
    }
  };

  const { run, error, isSettled, isPending } = useAsync({
    deferFn: ([purchaseOrderFormValues]: PurchaseOrderFormValues[]): Promise<
      PurchaseOrder | PurchaseOrder[]
    > => {
      return purchaseOrderFormValues.poStatus === PoStatus.SUBMITTED
        ? createSubmitPurchaseOrder(purchaseOrderFormValues, globalContext)
        : createDraftPurchaseOrder(purchaseOrderFormValues);
    },
    onResolve: redirectToDraftPurchaseOrders
  });

  const onSaveDraft = async () => {
    const firstAllocationStoreNum = 'allocations[0].storeNum';

    await trigger([firstAllocationStoreNum, PoFormField.TotalQuantity]);

    if (get(errors, firstAllocationStoreNum) || hasMaxQuantityError(errors)) {
      return;
    }

    const formValues: PurchaseOrderFormValues = getValues();

    setIsSaveDraftDisabled(true);
    setIsCancelDisabled(true);

    run(formValues);
  };

  const redirectAfterLegalEngineFail = () => {
    setIsSaveDraftDisabled(true);

    toggleModal(false);

    history.push(PurchaseOrdersRoute.Draft);

    globalContext.dispatch(
      openSnackbarAction(createGenericMessage(SUBMIT_ERROR_MESSAGE))
    );
  };

  const onSaveSubmitted = async (formValues: PurchaseOrderFormValues) => {
    toggleModal(true);

    setApiError(null);

    setIllegalResponse([]);

    const request: LegalEngineRequest = toLegalEngineRequest(formValues);

    let either: Either<LegalEngineResponseItem[], APIError>;

    if (!skipLegal) {
      either = await checkLegalLimits(request);
    } else {
      either = left([]);
    }

    if (either.isLeft()) {
      const responseItems: LegalEngineResponseItem[] = toIllegalResponseList(
        either.value
      );

      if (responseItems.length) {
        toggleModal(false);

        setIllegalResponse(responseItems);

        return;
      }

      run({
        ...formValues,
        poStatus: PoStatus.SUBMITTED
      });
    } else {
      const userFacingLegalError = either.value.errors.find(
        (e: APIErr) => e.code === LegalEngineErrorCode.UNABLE_TO_COMPUTE
      );

      if (userFacingLegalError) {
        setApiError(userFacingLegalError);

        toggleModal(false);
      } else {
        const either: Either<
          PurchaseOrder,
          APIError
        > = await handledCreateDraftPurchaseOrder(formValues);

        if (either.isLeft()) {
          redirectAfterLegalEngineFail();
        } else {
          const firstError: APIErr | undefined = first(either.value.errors);

          if (firstError) {
            setApiError(firstError);
          }

          toggleModal(false);
        }
      }
    }
  };

  const handleCancel = () => {
    if (isCreateMode) {
      if (isDirty) {
        setOpenAlert(true);
      } else {
        history.push(PurchaseOrdersRoute.Submitted);
      }
    }

    if (isEditMode) {
      if (isDirty) {
        setOpenAlert(true);
      } else {
        history.push(PurchaseOrdersRoute.Draft);
      }
    }
  };

  const handleHelpDrawerOpen = useCallback(
    (title: string) => () => {
      setHelpOpen(true);
      setHelpTitle(title);
    },
    []
  );

  const handleHelpDrawerClose = useCallback(() => {
    setHelpOpen(false);
  }, []);

  const autofixRate = (illegalStatesInfo: IllegalStateInfo[]) => {
    const formValues: PurchaseOrderFormValues = getValues();
    const correctionsByState: any = {};

    illegalStatesInfo.forEach(illegalState => {
      correctionsByState[illegalState.state.abbreviation] =
        illegalState.adjustedTerm;
    });

    const adjustedPricing = formValues.pricing.map(pricing => {
      if (!correctionsByState[pricing.state]) {
        return pricing;
      }

      if (
        parseInt(pricing.weeklyTerm, 10) <= correctionsByState[pricing.state]
      ) {
        return pricing;
      }

      const weeklyTerm = String(correctionsByState[pricing.state]);

      const totalRtoPrice = calculateValue(
        PricingCalculator.totalRtoPrice,
        pricing.weeklyRent,
        weeklyTerm
      );

      const biWeeklyTerm = calculateValue(
        PricingCalculator.biWeeklyTerm,
        weeklyTerm
      );

      const monthlyRent = calculateValue(
        PricingCalculator.monthlyRent,
        pricing.weeklyRent,
        String(PricingCalculator.monthlyMultiplier())
      );

      const monthlyTerm = calculateValue(
        PricingCalculator.monthlyTerm,
        totalRtoPrice,
        monthlyRent
      );

      const semiMonthlyTerm = calculateValue(
        PricingCalculator.semiMonthlyTerm,
        monthlyTerm
      );

      return {
        ...pricing,
        weeklyTerm,
        totalRtoPrice,
        biWeeklyTerm,
        semiMonthlyTerm,
        monthlyTerm
      };
    });

    skipLegal = true;
    onSaveSubmitted({ ...formValues, pricing: adjustedPricing });
  };

  useEffect(() => {
    if (isDirty && definedValues.length > 1) {
      setIsSaveDraftDisabled(false);
    } else {
      setIsSaveDraftDisabled(true);
    }
  }, [isDirty, setIsSaveDraftDisabled, definedValues.length]);

  useEffect(() => {
    purchaseOrder &&
      Object.keys(purchaseOrder).forEach(field => {
        if (purchaseOrder[field]) {
          trigger(field);
        }
      });
  }, [purchaseOrder, trigger]);

  useEffect(() => {
    if (isDirty && purchaseOrder) {
      setCancelText(buttonsTexts.cancel);
    } else {
      setCancelText(buttonsTexts.back);
    }
  }, [isDirty, purchaseOrder, setIsSaveDraftDisabled]);

  useEffect(() => {
    if (isPending) {
      setIsSubmitDisabled(true);
      setIsDeleteDisabled(true);
    } else {
      setIsSubmitDisabled(false);
      setIsDeleteDisabled(false);
    }
  }, [isPending, setIsSubmitDisabled, setIsDeleteDisabled]);

  useEffect(() => {
    if (isSettled && error) {
      setApiError(error);
      setIsSaveDraftDisabled(false);
      setIsSubmitDisabled(false);
      toggleModal(false);
    }
  }, [isSettled, error, setIsSaveDraftDisabled, setIsSubmitDisabled]);

  const apiErrDiv = apiError && (
    <div className={clsx(styles.error, styles.saveErrContainer)}>
      {apiError.message}
    </div>
  );

  return (
    <>
      <LooseWorkPrompt
        when={showLooseWorkPrompt}
        message="Leave page? Changes that you made may not be saved."
      />

      <Modal isOpen={isModalOpen}>
        <PleaseWaitBox />
      </Modal>

      <Modal isOpen={illegalResponse.length !== 0} color="danger">
        <IllegalValueBox
          illegalResponse={illegalResponse}
          stores={stores}
          onOk={autofixRate}
          poNumber={purchaseOrderId}
        />
      </Modal>

      <div className={styles.purchaseOrderPage}>
        <div className={styles.formContainer}>
          <FormProvider {...methods}>
            <form className={clsx([styles.formPurchaseOrder, styles.form])}>
              {purchaseOrderId ? (
                <input
                  type="hidden"
                  name={PoFormField.Id}
                  defaultValue={purchaseOrderId}
                  ref={register}
                />
              ) : null}
              <input type="hidden" name={PoFormField.PoStatus} ref={register} />
              <div className={styles.formPurchaseOrderContent}>
                <div className={styles.card}>
                  <div className={styles.cardTitleRounded}>
                    <span className={styles.headerContainer}>
                      <Hidden lgUp>
                        {isViewMode ? (
                          <BackIcon
                            className={styles.backArrow}
                            height="24"
                            width="24"
                            onClick={() =>
                              history.push(PurchaseOrdersRoute.Submitted)
                            }
                          />
                        ) : null}
                      </Hidden>
                      <span data-testid="poFormHeader">
                        {purchaseOrderHeader}
                        {isViewMode ? ' Details' : null}
                      </span>
                    </span>
                    {isEditMode && purchaseOrder ? (
                      <DeleteDraftPO
                        isDisabled={isDeleteDisabled}
                        draftId={purchaseOrder.id}
                        onDeleteConfirmationPopupStatusChange={(
                          isOpen: boolean
                        ) => {
                          setDeleteConfirmationPopupStatus(isOpen);
                        }}
                        onDeleteFinished={() => {
                          setDeleteFinished(true);
                        }}
                      />
                    ) : null}
                  </div>
                  <div className={styles.formCardsWrapper}>
                    <General isViewMode={isViewMode} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} xl={3}>
                        <FormCard>
                          <FormCardTitle>
                            {PanelTitle.ItemDetails}
                            <HelpText
                              helpTitle={helpTitle}
                              helpOpen={helpOpen}
                              handleHelpDrawerOpen={handleHelpDrawerOpen(
                                PanelTitle.ItemDetails
                              )}
                              handleHelpDrawerClose={handleHelpDrawerClose}
                            />
                          </FormCardTitle>
                          <FormCardContent>
                            <div className={styles.formBlock}>
                              <FieldWithTooltip
                                info="Please add a supplier name with at most 240 characters"
                                id="supplier-field"
                                title={`${PoFormLabel.Supplier}:`}
                                required={true}
                              >
                                <input
                                  name={PoFormField.Supplier}
                                  id="supplier-field"
                                  type="text"
                                  required={true}
                                  className={
                                    errors.supplier ? styles.invalid : ''
                                  }
                                  ref={register({
                                    required: true,
                                    maxLength: 240,
                                    validate: value => isNonEmptyValue(value)
                                  })}
                                  placeholder="Enter supplier name"
                                  maxLength={240}
                                  defaultValue={
                                    purchaseOrder && purchaseOrder.supplier
                                      ? purchaseOrder.supplier
                                      : ''
                                  }
                                  disabled={isViewMode}
                                  autoComplete="off"
                                />
                              </FieldWithTooltip>
                            </div>
                            <div className={styles.formBlock}>
                              <FieldWithTooltip
                                info="Please add brand name with at most 50 characters"
                                id="brand-field"
                                title={`${PoFormLabel.Brand}:`}
                                required={true}
                              >
                                <input
                                  id="brand-field"
                                  name={PoFormField.Brand}
                                  type="text"
                                  required={true}
                                  className={errors.brand ? styles.invalid : ''}
                                  ref={register({
                                    required: true,
                                    maxLength: 50,
                                    validate: value => isNonEmptyValue(value)
                                  })}
                                  placeholder="Select or enter brand"
                                  maxLength={50}
                                  defaultValue={
                                    purchaseOrder && purchaseOrder.brand
                                      ? purchaseOrder.brand
                                      : ''
                                  }
                                  disabled={isViewMode}
                                  autoComplete="off"
                                />
                              </FieldWithTooltip>
                            </div>
                            <div className={styles.formBlock}>
                              <FieldWithTooltip
                                info="Please add model with at most 50 characters"
                                id="model-field"
                                title={`${PoFormLabel.Model}:`}
                                required={true}
                              >
                                <input
                                  id="model-field"
                                  name={PoFormField.Model}
                                  type="text"
                                  required={true}
                                  className={errors.model ? styles.invalid : ''}
                                  ref={register({
                                    required: true,
                                    maxLength: 50,
                                    validate: value => isNonEmptyValue(value)
                                  })}
                                  maxLength={50}
                                  placeholder="Enter model"
                                  defaultValue={
                                    purchaseOrder && purchaseOrder.model
                                      ? purchaseOrder.model
                                      : ''
                                  }
                                  disabled={isViewMode}
                                  autoComplete="off"
                                />
                              </FieldWithTooltip>
                            </div>
                            <Condition
                              purchaseOrder={purchaseOrder}
                              disabled={isViewMode}
                            />
                            <div className={styles.formBlock}>
                              <>
                                <label>
                                  {`${PoFormLabel.SupplierOrderNo}: (Opt.)`}
                                </label>
                                <input
                                  name={PoFormField.SupplierOrderNo}
                                  type="text"
                                  ref={register({
                                    maxLength: 150,
                                    validate: value => isNonEmptyValue(value)
                                  })}
                                  placeholder="Enter supplier order or confirmation number"
                                  defaultValue={
                                    purchaseOrder &&
                                    purchaseOrder.supplierOrderNo
                                      ? purchaseOrder.supplierOrderNo
                                      : ''
                                  }
                                  disabled={isViewMode}
                                  autoComplete="off"
                                />
                              </>
                            </div>
                            <div className={styles.formBlock}>
                              <Description
                                purchaseOrder={purchaseOrder}
                                disabled={isViewMode}
                              />
                            </div>
                          </FormCardContent>
                        </FormCard>
                      </Grid>
                      <Grid item xs={12} lg={6} xl={3}>
                        <FormCard>
                          <FormCardTitle>
                            {PanelTitle.InventoryDetails}
                            <HelpText
                              helpTitle={helpTitle}
                              helpOpen={helpOpen}
                              handleHelpDrawerOpen={handleHelpDrawerOpen(
                                PanelTitle.InventoryDetails
                              )}
                              handleHelpDrawerClose={handleHelpDrawerClose}
                            />
                          </FormCardTitle>
                          <FormCardContent>
                            <input
                              type="hidden"
                              name={PoFormField.StartClassificationsSelect}
                              ref={register}
                              defaultValue={
                                purchaseOrder?.startClassificationsSelect ||
                                (getValues()
                                  .startClassificationsSelect as string)
                              }
                            />
                            <Classifications purchaseOrder={purchaseOrder} />
                          </FormCardContent>
                        </FormCard>
                      </Grid>
                      <Grid item xs={12} lg={6} xl={3}>
                        <FormCard>
                          <FormCardTitle>
                            {PanelTitle.Allocations}
                            <HelpText
                              helpTitle={helpTitle}
                              helpOpen={helpOpen}
                              handleHelpDrawerOpen={handleHelpDrawerOpen(
                                PanelTitle.Allocations
                              )}
                              handleHelpDrawerClose={handleHelpDrawerClose}
                            />
                          </FormCardTitle>
                          <FormCardContent>
                            <Allocations
                              purchaseOrder={purchaseOrder}
                              stores={stores}
                              disabled={isViewMode}
                            />
                          </FormCardContent>
                        </FormCard>
                      </Grid>
                      <Grid item xs={12} lg={6} xl={3}>
                        <FormCard>
                          <FormCardTitle>
                            {PanelTitle.Pricing}
                            <HelpText
                              helpTitle={helpTitle}
                              helpOpen={helpOpen}
                              handleHelpDrawerOpen={handleHelpDrawerOpen(
                                PanelTitle.Pricing
                              )}
                              handleHelpDrawerClose={handleHelpDrawerClose}
                            />
                          </FormCardTitle>
                          <FormCardContent>
                            <Pricing purchaseOrder={purchaseOrder} />
                          </FormCardContent>
                        </FormCard>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className={styles.formPurchaseOrderFooter}>
                <div className={styles.actionsContainer}>
                  <>
                    {isViewMode ? (
                      <>
                        <div className={styles.leftActions}>
                          <Button
                            variant="outlined"
                            color="primary"
                            data-testid="buttonBack"
                            type="button"
                            startIcon={<ArrowBackIcon />}
                            onClick={() =>
                              history.push(PurchaseOrdersRoute.Submitted)
                            }
                          >
                            {buttonsTexts.back}
                          </Button>
                          <div className={styles.intermediateActions}>
                            {!isCreateMode && (
                              <Button
                                variant="outlined"
                                color="primary"
                                data-testid="buttonDuplicatePO"
                                disabled={isPending}
                                onClick={handleDuplicate}
                              >
                                {buttonsTexts.duplicatePO}
                              </Button>
                            )}
                          </div>
                        </div>
                        <PoCanceller
                          poId={purchaseOrderId}
                          redirectPath={`${PurchaseOrdersRoute.Index}/${purchaseOrderId}`}
                          cancelButton={({ onClick }: CancelButtonProps) => (
                            <Button
                              variant="contained"
                              size="large"
                              data-testid={buttonTestId}
                              customColor="red"
                              disabled={isCanceled}
                              onClick={onClick}
                            >
                              {isCanceled ? 'Cancelled PO' : 'Cancel PO'}
                            </Button>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <Hidden lgUp>{apiErrDiv}</Hidden>
                        <div className={styles.leftActions}>
                          <Button
                            variant="outlined"
                            color="primary"
                            data-testid="buttonCancel"
                            type="button"
                            disabled={isCancelDisabled}
                            onClick={handleCancel}
                            {...(cancelText === buttonsTexts.back && {
                              startIcon: <ArrowBackIcon />
                            })}
                          >
                            {purchaseOrderId ? cancelText : buttonsTexts.cancel}
                          </Button>
                          <Hidden mdDown>{apiErrDiv}</Hidden>
                          <div className={styles.intermediateActions}>
                            <Button
                              variant="outlined"
                              color="primary"
                              data-testid="buttonDraft"
                              disabled={isSaveDraftDisabled}
                              onClick={onSaveDraft}
                            >
                              {buttonsTexts.saveDraft}
                            </Button>
                          </div>
                        </div>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          data-testid="buttonSubmit"
                          disabled={!isValid || isSubmitDisabled}
                          onClick={handleSubmit(onSaveSubmitted)}
                        >
                          {buttonsTexts.submitOrder}
                        </Button>
                      </>
                    )}
                  </>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <CancelPurchaseOrderDialog
        purchaseOrderId={purchaseOrderId}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        resetForm={reset}
        isCreateMode={isCreateMode}
        isEditMode={isEditMode}
      />
    </>
  );
};
