import React, { useEffect } from 'react';
import { useAsync } from 'react-async';

import { useUserPermissions } from '../../context/user/PermissionsProvider';
import { useGlobalContext } from '../../context/global/GlobalContext';
import { getPoSubTypes } from '../../api/purchase-orders';
import { setPoSubTypesAction } from '../../context/global/po-sub-types/po-sub-types-actions';

export const PoSubTypesFetcher = () => {
  const { dispatch } = useGlobalContext();

  const { canSelectPoSubType } = useUserPermissions();

  const { data } = useAsync({
    promiseFn: getPoSubTypes
  });

  useEffect(() => {
    if (canSelectPoSubType && data) {
      dispatch(setPoSubTypesAction(data));
    }
  }, [canSelectPoSubType, data, dispatch]);

  return <></>;
};
