import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { ArrayField, useFormContext } from 'react-hook-form';

import {
  RateAndTermParams,
  RateAndTermResponse,
  getRateAndTerm
} from '../../../../../api/purchase-orders';
import { Either, left, right } from '../../../../../utils/Either';
import { APIError } from '../../../../../domain/APIError';
import {
  FormAllocation,
  firstStoreNumInState
} from '../allocations/allocations-list/FormAllocation';
import { orderedPricingFields, pricingConfig } from '../po-form-config';
import {
  ActionType,
  useAutoPricingDispatch,
  useAutoPricingState
} from './context/AutoPricingProvider';
import { FormPricing, FormPricingKeys } from './pricing-list/FormPricing';
import { LoadingIndicator } from './loading-indicator/LoadingIndicator';

import formStyles from '../PurchaseOrderForm.module.scss';

const callRateAndTerm = async (
  params: RateAndTermParams
): Promise<Either<RateAndTermResponse, APIError>> => {
  try {
    return left(await getRateAndTerm(params));
  } catch (e) {
    return right(e);
  }
};

interface Props {
  index: number;
  field: Partial<ArrayField<FormPricing>>;
  allocations: FormAllocation[];
}

export const autoPricingTestId = 'autoPricing';

export const AutoPricing = (props: Props) => {
  const { index, field, allocations } = props;
  const [apiError, setApiError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { errors } = useFormContext();
  const { isChanged, allFieldsPresent, fixedParams } = useAutoPricingState();
  const dispatch = useAutoPricingDispatch();

  const storeNum =
    field.state && firstStoreNumInState(allocations, field.state);

  const setPricingValues = useCallback(
    ({
      weeklyRate = null,
      weeklyTerm = null,
      totalCost = null,
      biWeeklyRate = null,
      biWeeklyTerm = null,
      semiMonthlyRate = null,
      semiMonthlyTerm = null,
      monthlyRate = null,
      monthlyTerm = null
    }: RateAndTermResponse): RateAndTermResponse => {
      return {
        weeklyRate,
        weeklyTerm,
        totalCost,
        biWeeklyRate,
        biWeeklyTerm,
        semiMonthlyRate,
        semiMonthlyTerm,
        monthlyRate,
        monthlyTerm
      };
    },
    []
  );

  const handleRateAndTermCall = useCallback(async () => {
    if (storeNum && fixedParams) {
      setPricingValues({});
      setApiError('');

      setLoading(true);
      const either = await callRateAndTerm({ ...fixedParams, storeNum });
      setLoading(false);
      if (either.isLeft()) {
        // Set auto-pricing object which is used by pricing components to fetch pricing values
        dispatch({
          type: ActionType.SET_AUTO_PRICING,
          payload: setPricingValues(either.value)
        });
      } else {
        const messsgae = either.value.errors[0]?.message;
        setApiError(messsgae || 'Calculations API failed');
      }

      setTimeout(() => {
        dispatch({
          type: ActionType.SET_AUTO_PRICING,
          payload: {
            weeklyRate: null,
            weeklyTerm: null,
            totalCost: null,
            biWeeklyRate: null,
            biWeeklyTerm: null,
            semiMonthlyRate: null,
            semiMonthlyTerm: null,
            monthlyRate: null,
            monthlyTerm: null
          }
        });
      }, 100);

      dispatch({ type: ActionType.SET_IS_CHANGED, payload: false });
    }
  }, [storeNum, fixedParams, setPricingValues, setApiError, dispatch]);

  const pricingErrors = get(errors, `pricing[${index}]`);
  const firstError =
    pricingErrors &&
    orderedPricingFields
      .filter(field => {
        const fieldError = pricingErrors[field];
        const isMin = fieldError?.type.includes('min');
        const isMax = fieldError?.type.includes('max');

        return fieldError && (isMin || isMax);
      })
      .map(field => ({ ...pricingErrors[field], field }))[0];

  const firstErrorField = firstError && firstError.field;
  const firstErrorType = firstError && firstError.type;

  useEffect(() => {
    if (firstErrorField && firstErrorType) {
      const config = pricingConfig[firstErrorField as FormPricingKeys];
      const {
        label,
        validations: { min, max }
      } = config;

      if (firstErrorType === 'min') {
        setApiError(`${label} is below ${min}. Please modify Cost per item!`);
        setPricingValues({});
      }
      if (firstErrorType === 'max') {
        setApiError(`${label} is above ${max}. Please modify Cost per item!`);
        setPricingValues({});
      }
    } else if (apiError) {
      setApiError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstErrorField, firstErrorType, setPricingValues]);

  useEffect(() => {
    if (isChanged) {
      handleRateAndTermCall();
    }
  }, [isChanged, handleRateAndTermCall]);

  useEffect(() => {
    if (!allFieldsPresent) {
      setPricingValues({});
    }
  }, [allFieldsPresent, setPricingValues]);

  return useMemo(
    () => (
      <div data-testid={autoPricingTestId}>
        {isLoading && <LoadingIndicator />}
        {apiError && !isLoading && (
          <Grid container spacing={0} justify="center">
            <Grid item>
              <div className={formStyles.error}>{apiError}</div>
            </Grid>
          </Grid>
        )}
      </div>
    ),
    [apiError, isLoading]
  );
};
